import React from "react";
import { Router } from "@reach/router";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { IntroCopy } from "src/components/landing-page/intro-copy";
import { ProjectGrid } from "src/components/project-grid";

const LandingPage = ({
  location,
  pageContext,
  projects,
  filterTags,
  filterSettings,
}) => {
  const { main, meta } = pageContext;
  return (
    <RevealOnMount location={location}>
      <SEO
        metaInfo={meta}
        path={location?.pathname}
        slug={main.slug?.current || "/"}
      />

      {location.pathname === "/" && <IntroCopy copy={main.introHeadline} />}

      <Router primary={false}>
        <ProjectGrid
          default
          location={location}
          projects={projects}
          filterTags={filterTags}
          filterSettings={filterSettings}
        />
        <ProjectGrid
          path={`/work/tagged/*joinedTags`}
          location={location}
          projects={projects}
          filterTags={filterTags}
          filterSettings={filterSettings}
        />
      </Router>
    </RevealOnMount>
  );
};

export default LandingPage;
