import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { Image } from "src/components/image";
import { Player } from "src/components/player";
import { ThreeUpVideo } from "../three-up-video";
import { ConditionalWrapper } from "src/components/conditional-wrapper";
import {
  useStoreContext,
  useSetActiveTags,
  useToggleFilter,
  useEnableCustomCursor,
  useSetCustomCursorType,
} from "src/context/siteContext";

import { ReactComponent as Plus } from "src/images/svg/Plus.svg";

import * as styles from "./project-grid.module.css";
import { set } from "lodash";

const ProjectTile = ({ projectData, index }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const setCustomCursorType = useSetCustomCursorType();
  const enableCustomCursor = useEnableCustomCursor();

  const onMouseEnter = () => {
    enableCustomCursor(true);
    if (projectData.playInline) {
      setCustomCursorType("play");
    } else {
      setCustomCursorType("plus");
    }
  };

  const onMouseLeave = () => {
    enableCustomCursor(false);
  };

  const onTitleClick = () => {
    setIsPlaying(!isPlaying);
  };

  const imageAsset =
    projectData.indexImage?.asset ||
    projectData.media?.image?.asset ||
    undefined;
  return (
    <div
      key={projectData.slug.current}
      className={cx(
        styles.item,
        styles[`item${index + 1}`],
        projectData.fullWidth ? styles.fullWidth : ""
      )}
    >
      <ConditionalWrapper
        condition={!projectData.playInline}
        wrapper={children => (
          <Link
            className={cx("reset x", styles.projectCardLink)}
            to={`/work/${projectData.slug.current}`}
            state={{
              modal: true,
              shouldUpdateScroll: false,
            }}
            onClick={() => (window.INDEX_SCROLL_POSITION = window.scrollY)}
          >
            {children}
          </Link>
        )}
      >
        <div className={styles.projectCard}>
          {projectData.playInline &&
          (projectData.media.video?.url || projectData.media.audio) ? (
            <Player
              title={projectData.title}
              className={styles.projectPlayer}
              wrapperClassName={styles.projectPlayerWrapper}
              posterImageId={imageAsset._id}
              url={projectData.media.video.url || projectData.media.audio}
              enableCustomAspectRatio={Boolean(
                projectData.videoMetaData?.aspectRatio && projectData.fullWidth
              )}
              aspectRatio={projectData.videoMetaData?.aspectRatio}
              isPlaying={isPlaying}
            />
          ) : projectData.playInline &&
            projectData.media.threeUpVideo &&
            projectData.media.threeUpVideo.length === 3 ? (
            <ThreeUpVideo media={projectData.media} isPlaying={isPlaying} />
          ) : imageAsset ? (
            <Image
              className={cx("x", styles.projectImage)}
              imageId={imageAsset._id}
              alt={projectData.title}
              style={{ height: `100%` }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          ) : null}

          <div className={styles.projectDetails}>
            <ConditionalWrapper
              condition={projectData.playInline}
              wrapper={children => (
                <button
                  onClick={onTitleClick}
                  className={cx("reset x", styles.projectCardLink)}
                >
                  {children}
                </button>
              )}
            >
              <h3 className={cx("heading2", styles.projectTitle)}>
                {projectData.title}
              </h3>
            </ConditionalWrapper>
            <p className={cx("body", styles.projectTags)}>
              {projectData.tags &&
                projectData.tags.map(tag => tag.title).join(", ")}
            </p>
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export const ProjectGrid = ({
  location,
  projects,
  filterTags,
  filterSettings,
  joinedTags,
}) => {
  const toggleFilter = useToggleFilter();
  const { activeTags } = useStoreContext();
  const setActiveTags = useSetActiveTags();

  let disableReveals = false;
  if (location.state?.disableReveals != undefined) {
    disableReveals = location.state.disableReveals;
  }

  useEffect(() => {
    const activeTagSlugs = joinedTags?.split(`+`);
    let newActiveTags = [];
    const x = activeTagSlugs?.map(slug => {
      // find active category tag objects
      const categoryTagObject = filterTags.work.find(
        tag => tag.node.slug.current === slug
      );
      categoryTagObject && newActiveTags.push(categoryTagObject.node);

      // find active artist tag objects
      const artistTagObject = filterTags.artists.find(
        tag => tag.node.slug.current === slug
      );
      artistTagObject && newActiveTags.push(artistTagObject.node);
    });

    if (newActiveTags.length > 0) {
      setActiveTags([...newActiveTags]);
    }
    return;
  }, [joinedTags]);

  const activeTagSlugs = activeTags.map(tag => tag.slug.current);

  const activeProjects =
    activeTags && activeTags.length
      ? projects.filter(project => {
          // collect all project tag slugs
          const projectTags = project.node.content.main.tags?.map(
            tag => tag.slug.current
          );

          // collect all artist tag slugs
          const artistTags = project.node.content.main.artistCredits?.map(
            credit => {
              return credit.artists.map(artist => artist.slug.current);
            }
          );

          // concat project tags into a single array
          let allProjectTags = [];

          if (projectTags?.length && artistTags?.length) {
            allProjectTags = projectTags.concat(artistTags).flat();
          } else if (projectTags?.length) {
            allProjectTags = projectTags;
          } else if (artistTags?.length) {
            allProjectTags = artistTags;
          } else {
            allProjectTags = [];
          }

          // compare with active tag slugs
          if (allProjectTags?.length) {
            return activeTagSlugs.some(tag => allProjectTags.indexOf(tag) >= 0);
          }
        })
      : projects;

  const projectsPlaced = [];
  const projectRows =
    activeProjects &&
    activeProjects.map((project, index) => {
      const currProjectsPlaces = projectsPlaced;
      const projectData = {
        ...project.node.content.main,
        videoMetaData: project.node.videoMetaData,
      };
      if (projectsPlaced.includes(project?.node?._id)) {
        return; // skip
      }
      const row = [];

      if (projectData.fullWidth) {
        row.push(projectData);
        projectsPlaced.push(project.node._id);
        return row;
      } else {
        row.push(projectData);
        projectsPlaced.push(project.node._id);

        if (
          activeProjects[index + 1] &&
          !Boolean(activeProjects[index + 1].node.content.main.fullWidth)
        ) {
          row.push({
            ...activeProjects[index + 1].node.content.main,
            videoMetaData: activeProjects[index + 1].node.videoMetaData,
          });
          projectsPlaced.push(activeProjects[index + 1].node._id);
        }
        return row;
      }
    });

  return (
    <div className={styles.root}>
      <div className={styles.filterToggle}>
        <button
          onClick={toggleFilter}
          className={cx("reset", styles.filterToggleButton)}
        >
          <span className={cx("heading3")}>Filter Work</span>
          <Plus className={cx(styles.filterTogglePlus)} />
        </button>
      </div>
      {activeTags.length > 0 && (
        <p className={cx("heading3", styles.activeTags)}>
          {activeTags.map(tag => tag.title || tag.name).join(" * ")}
        </p>
      )}
      <div className={cx(styles.grid)}>
        {projectRows &&
          projectRows.map((row, index) => {
            // default to fifty/fifty layout
            let rowLayoutClass = styles.fiftyFifty;
            if (row && row.length > 1) {
              if (
                (row[0].indexImage?.asset?.metadata.dimensions.aspectRatio ||
                  row[0].media.image.asset?.metadata.dimensions.aspectRatio) >
                  1.2 &&
                (row[1].indexImage?.asset?.metadata.dimensions.aspectRatio ||
                  row[1].media.image.asset?.metadata.dimensions.aspectRatio) <
                  1.2
              ) {
                rowLayoutClass = styles.sixtyForty;
              } else if (
                (row[0].indexImage?.asset?.metadata.dimensions.aspectRatio ||
                  row[0].media.image.asset?.metadata.dimensions.aspectRatio) <
                  1.2 &&
                (row[1].indexImage?.asset?.metadata.dimensions.aspectRatio ||
                  row[1].media.image.asset?.metadata.dimensions.aspectRatio) >
                  1.2
              ) {
                rowLayoutClass = styles.fortySixty;
              }
            }
            return (
              <div
                className={cx(styles.row, rowLayoutClass)}
                key={`project-grid-row-${index}`}
              >
                {row &&
                  row.map((projectData, index) => (
                    <ProjectTile
                      projectData={projectData}
                      index={index}
                      key={`project-grid-item-${index}`}
                    />
                  ))}
              </div>
            );
          })}
      </div>
    </div>
  );
};
