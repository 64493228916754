import React from "react";
import cx from "classnames";

import styles from "./intro-copy.module.css";


export const IntroCopy = ({ copy }) => {
  return (
    <section className={cx(styles.introCopy)}>
      <h2 className={cx("heading4", styles.headline)}>{copy}</h2>
    </section>
  );
};
